<!--
 * ======================================
 * 说明： 分数列表
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/7/23 0:31
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="mobile-score-layout" v-loading="loading">
    <div class="mobile-score-main w-11/12 mx-auto">
      <div class="mobile-score-header w-full py-3">
        <h2 class="text-center text-xl font-extrabold text-gray-900">
          智慧校园综合素质评价管理系统
        </h2>
        <p class="mt-2 text-center text-sm text-gray-200">
          欢迎登录学生综合素质评价系统
        </p>
      </div>
    </div>

    <div class="mobile-score-main w-11/12 mx-auto mb-4">
      <div
        class="
          bg-white
          rounded
          py-2
          px-4
          mt-4
          text-['#666666'] text-sm
          flex
          items-center
          justify-between
        "
      >
        <label>班级：{{ title }}班</label>
        <label
          >{{ classinfo.semester_year }}年第{{ classinfo.semester }}学期</label
        >
      </div>

      <div
        v-for="(item, index) in list"
        :key="index"
        class="bg-white rounded py-2 px-4 mt-4 text-['#666666'] text-sm"
      >
        <div
          class="border-b pb-2 mb-2 flex items-center justify-start text-black"
          style="font-weight: bold"
        >
          <div class="score-item-person mr-2">
            <img width="100%" height="100%" src="@/assets/person.png" />
          </div>
          姓名：{{ item.username }}
        </div>
        <div class="score-item-main p-2">
          <div>
            学号：<label class="score-item-value">{{ item.no }}</label>
          </div>
          <div v-for="column in columns" :key="column.field">{{ column['label'] }}：<label class="score-item-value">{{ item[column.field] }}</label></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      title: "", // 班级名称
      userinfo: {},
      classinfo: {},
      columns: [],
      list: [],
      isMaster: false, // 是否未班主任
      params: {
        name: "",
        pageSize: 999,
        pageIndex: 1,
        roomId: "",
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.classname = this.$route.query.classname;
    this.handleGetStudents();
  },
  methods: {
    // 获取学生列表
    async handleGetStudents() {
      this.loading = true;
      await this.$cloud
        .get("/login/info")
        .then(async (res) => {
          this.userinfo = res;
          if (res.role === "admin" || res.role === "teacher") {
            this.roomId = this.$route.query["id"];
            // 判断是否为班主任
            this.isMaster = parseInt(this.$route.query["isMaster"]) === 1;

            if (!this.isMaster) {
              this.columns = this.columns.filter(
                (item) => !["rate"].includes(item.field)
              );
            }

            delete this.params["groupId"];
            this.params["roomId"] = this.roomId;
            let subjectRoom = [];
            if (
              res.subjectRoom &&
              typeof res.subjectRoom == "object" &&
              res.subjectRoom.length
            ) {
              res.subjectRoom.forEach((item) => {
                subjectRoom.push(item.room);
              });
            }

            let arrays = [];
            if (res.masterRoom && subjectRoom.length) {
              arrays = [res.masterRoom].concat(subjectRoom);
            } else if (res.masterRoom && !subjectRoom.length) {
              arrays = [res.masterRoom];
            } else if (!res.masterRoom && subjectRoom.length) {
              arrays = subjectRoom;
            }
            let find = arrays.find(
              (item) => parseInt(item.id) === parseInt(this.roomId)
            );

            let name =
              find.name.length > 1
                ? String(find.name)
                : "0" + String(find.name);
            this.title = find.level + name;
            this.classinfo = find;

            await this.getScoreList("teacher"); // 教师重新获取
            await this.getScoreTableHeader(); // 获取列表中的评分分数表头
            await this.getList(); // 获取列表学生数据
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    // 获取列表
    getList() {
      this.loading = true;
      this.params.roomId = this.$route.query.id;
      this.$cloud
        .get("/evaluation/student_list", this.params)
        .then((res) => {
          this.loading = false;
  
          // 教师合并评分数据
          this.list = res.list.map((item) => {
            let arrays = this.scoreList[item.id];
            let obj = {};
            arrays.map((i) => {
              obj[i.no] = i.score;
            });
            return {
              ...item,
              ...obj,
            };
          });
          console.log("resqqq::", this.list);
          console.log("resqqq::11", this.columns);
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },

    //教师获取列表中的评分分数表头
    async getScoreTableHeader() {
      this.loading = true;
      await this.$cloud
        .post("/mark/index", {
          roomId: this.roomId,
          from: "teacher",
        })
        .then((res) => {
          console.log('res',res)
          this.loading = false;
          let c = [];
          this.columns.map((item) => {
            if (!item.score) {
              c.push(item);
            }
          });
          res.map((item, index) => {
            let obj = {
              field: item.no,
              label: item.indicator.map((i) => i.name).join("、"),
              align: "center",
              width: item.indicator.length * 100 + "px",
              score: true,
            };
            c.splice(2 + index, 0, obj);
          });
          this.columns = c;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // 获取列表中的评分分数
    async getScoreList(from) {
      this.loading = true;
      await this.$cloud
        .post("record/student_list_by_teacher", {
          roomId: this.roomId,
          from: from,
        })
        .then((res) => {
          this.loading = false;
          this.scoreList = res;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-score-layout {
  width: 100%;
  height: 100vh;
  background-color: #eef4f9;
  overflow: hidden;
  overflow-y: auto;
}
.mobile-score-main {
  .mobile-score-header {
    margin: 1rem auto 0;
    background: url("~@/assets/southeast.jpg") center -9rem no-repeat;
  }
  .score-item-person {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    overflow: hidden;
  }
  .score-item-main {
    line-height: 1.7rem;
    color: #999999;
    .score-item-value {
      color: #333333;
    }
  }
}
</style>
